<template>
  <v-row>
    <v-col>
      <v-text-field
        v-model="title"
        :label="label"
        :error-messages="errorMessages"
        readonly
        outlined
        dense
      ></v-text-field
    ></v-col>
    <v-col>
      <v-dialog v-model="dialog" persistent max-width="1000">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on">
            <v-icon dark>mdi-application</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            {{ label }}
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Поиск"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="items"
            single-select
            item-key="_id"
            show-select
            class="elevation-1"
          >
          </v-data-table>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeWindow"
              >Закрыть</v-btn
            >
            <v-btn color="blue darken-1" text @click="selectItems"
              >Выбрать</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
/*
  Упрощенный вариант компонента выбора элемента из базы данных.
  Используется когда нужно выбрать для свойства одного объекта другой (например в объекте Договор нужно выбрать объект Партнер)
  Принимает значения:
  model: это Объект который уже выбран в качестве свойства (например, когда редактируется Договор и в нем уже выбран Партнер)
  tag: это тег типа элементов базы данных. Соотносится с тэгами в store/universal
  label: описание для визуального представления в форме
  Дисклеймер:
  Пока этот компонент умеет работать только с выбором ОДНОГО объекта. В дальнейшем нужно научить выбирать его несколько при необходимости (в массив)
  Также компонент поумолчанию для представления используется свойство name выбранного элемента
*/

export default {
  props: {
    model: {
      required: true,
    },
    tag: {
      required: true,
      type: String,
      default: "partner",
    },
    label: String,
    errorMessages: Array,
  },

  data: () => ({
    title: "",
    search: "",
    dialog: false,
    items: [],
    headers: [],
    selected: [],
    id: "",
  }),

  watch: {
    // Обновляется значение представления выбранного в текстовом поле
    model: function (value) {
      if (value) {
        if (typeof value === "object") {
          this.id = value._id;
        } else {
          this.id = value;
        }
        this.title = value;
        this.updateSelection();
      }
    },
  },

  async mounted() {
    // Загружается весь список доступных пользователю элементов, на основании tag
    const res = await this.$store.dispatch("uniFetchAll", {
      name: this.tag,
    });

    if (res && Array.isArray(res) && res.length > 0) {
      this.items = res.map((el) => {
        return {
          ...el,
          id: el._id,
        };
      });

      // По tag получаем заголовки для таблицы выбора в модальном окне
      this.headers = [...this.$store.getters.allHeaders(this.tag)];
    }

    // После этого обновляем выбранные элементы
    this.updateSelection();
  },

  methods: {
    closeWindow() {
      this.dialog = false;
    },

    // Вызывается при закрытии окна с сохранением выбранного
    selectItems() {
      this.dialog = false;
      if (this.selected.length > 0) {
        // Обновить представление в текстовой строке
        this.title = this.selected[0].name;
        // Передать как событие компоненту в котром находится этот компонент
        // При этом родительский компонент должен слушать @itemSelected
        this.$emit("itemSelected", this.selected[0]);
      }
    },

    // В случае если выбранный элемент был передан в компонент из родительского компонента,
    // обновить в списке модального окна выбранные элементы
    updateSelection() {
      if (this.model) {
        if (typeof this.model === "object") {
          this.id = this.model._id;
        } else {
          this.id = this.model;
        }
      }
      if (this.id) {
        this.selected = this.items.filter(
          (item) => item._id.toString() === this.id
        );

        if (this.selected.length > 0) {
          this.title = this.selected[0].name;
        }
      }
    },
  },
};
</script>

<style></style>
